import { onMounted, ref } from 'vue';

const scrollPosition = ref(0);
export default function useDisableScroll() {
  let body: HTMLElement | null = null;

  onMounted(() => {
    body = document.querySelector('body');
  });

  const enableScroll = () => {
    document.body.classList.remove('noscroll');
    if (body && body.style) {
      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
    }
    window.scrollTo(0, scrollPosition.value);
  };

  const disableScroll = () => {
    scrollPosition.value = window.scrollY;
    if (body) {
      document.body.classList.add('noscroll');
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition.value}px`;
      body.style.width = '100%';
    }
  };

  return {
    disableScroll,
    enableScroll,
  };
}